<template lang="pug">
include ../../../../configs/template
div.row.text-left.pa-4
  div.col-sm-12.col-md-6
    +data-info('numberProtocol','sailorDocument.full_number_protocol')
  div.col-sm-12.col-md-6
    +data-info('course','sailorDocument.course[nameLang]')
  div.col-sm-12.col-md-6
    +data-info('dateEffective','getDateFormat(sailorDocument.date_start)')
  div.col-sm-12.col-md-6
    +data-info('dateTermination','getDateFormat(sailorDocument.date_end)')
  div.col-sm-12.col-md-6
    +data-info-status('dateTermination','sailorDocument.is_disable ? $t("isDisable") : $t("isNotDisable")', 'getPaymentStatus(!sailorDocument.is_disable)')

</template>

<script>
import { getDateFormat, getPaymentStatus } from '@/mixins/main'
import { mapState } from 'vuex'

export default {
  name: 'BackOfficeCoursesInfo',
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getDateFormat,
      getPaymentStatus
    }
  },
  computed: {
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    })
  }
}
</script>
